import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  navigatedToPage,
} from '../../../actions/segmentEvents';
import MetaTags from '../../../components/MetaTags';
import homePhotos from './images';
import config from '../../../config';

const pageMeta = {
  title: 'Morro Negrito - Panama Surf Camp',
  path: '/',
  url: `${config.hostForSEO}/`,
};

class Homepage extends Component {
  static propTypes = {
    doNavigatedToPage: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.props.doNavigatedToPage('Home', { ...pageMeta });
  }

  render() {
    return (
      <div className="home-page">
        <MetaTags
          title={pageMeta.title}
          description="Morro Negrito is a panama surf camp that has been in operation since 1998. Located on an island off the coast of Chiriqui..."
          urlPath={pageMeta.path}
        />
        <div className="col-md-6">
          <h1>Morro Negrito Surf Camp</h1>
          <p>In June 1998 Morro Negrito surf camp opened its doors to the world and ever since has been recognized as a top surf destination in Central America. Located on a pristine island off the Pacific coast of Panama, you will have wave after wave to you and your friends. Multiple islands surround Morro Negrito and each one provides its own unique wave. From fast hollow point breaks to sand bottom beach breaks, we've got you covered.</p>
          <br />
          <p>At Morro Negrito you will find a wide assortment of outdoor activities. You can search our nearby mangroves for Crocodiles, look for Monkeys in the jungle, take a hike to our private waterfall or fish in the world class waters surrounding Morro Negrito.
          Morro Negrito surf camp is a wonderful place for almost anyone to go. The surf breaks range from a beginner level to a professional level. The scenery is gorgeous so even if you don't surf, Morro Negrito is great place to be!</p>
        </div>
        <div className="gallery-viewer col-md-6">
          <div className="gallery-main">
            <Link to="/waves">
              <img
                alt={homePhotos[0].alt}
                border="none"
                src={homePhotos[0].src}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Homepage.fetch = [];

export default connect(
  state => ({}),
  dispatch => ({
    doNavigatedToPage: (name, properties) =>
      dispatch(navigatedToPage(name, properties)),
  }),
)(Homepage);
